import { getFilteredDiscountCodes } from "src/components/Account/BillingPortal/ChangePlan/discountCodeUtil"
import { ICustomer } from "src/components/Account/types"
import {
  useFetchCreateSubscriptionEstimates,
  useFetchCurrentSubscription,
} from "src/data/billing/queries/billingQueries"
import {
  IAvailablePlan,
  ICreateSubscriptionEstimateBody,
  TCurrentSubscription,
  TSubscriptionCurrencyCode,
} from "src/data/billing/types/billingTypes"
import { useFetchSubscriptionEstimates } from "src/data/subscription/queries/subscriptionQueries"
import { TSubscriptionEstimates } from "src/data/subscription/types/subscriptionTypes"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { useHomeTokens } from "src/hooks/useHomeTokens"

export function useFetchPlanEstimates({
  customer,
  currencyCode,
  availablePlans,
  currentSubscription,
  numberOfHomes,
  discountCode,
}: {
  customer: ICustomer | undefined
  currencyCode: TSubscriptionCurrencyCode | undefined
  availablePlans: IAvailablePlan[] | undefined
  currentSubscription: TCurrentSubscription | undefined
  numberOfHomes: number | undefined
  discountCode?: string
}) {
  const { user_id } = useGetUser()

  const fetchSubscription = useFetchCurrentSubscription({
    userId: user_id,
  })

  const { available: homeTokensFeatureAvailable } = useHomeTokens({
    useCachedData: false,
  })

  const subscription = fetchSubscription.data ?? null
  const bodies = createSubscriptionEstimateBodies(
    availablePlans,
    subscription,
    customer,
    numberOfHomes,
    currentSubscription,
    discountCode
  )

  const fetchCreateSubscriptionEstimates = useFetchCreateSubscriptionEstimates({
    bodies,
    options: {
      enabled:
        !!customer &&
        !!currencyCode &&
        !!availablePlans &&
        !homeTokensFeatureAvailable,
    },
  })

  const fetchSubscriptionEstimates = useFetchSubscriptionEstimates({
    availablePlans: availablePlans || [],
    quantity: subscription?.plan_quantity || numberOfHomes || 1,
    customer,
    discountCode,
    options: {
      enabled:
        !!customer &&
        !!availablePlans &&
        !!currencyCode &&
        homeTokensFeatureAvailable,
    },
  })

  const transformedSubscriptionEstimates: TSubscriptionEstimates[] =
    fetchSubscriptionEstimates.data?.map((est) => ({
      estimate: { ...est },
    })) || []

  const fetchSubscriptionsEstimatesResult = {
    ...fetchSubscriptionEstimates,
    data: transformedSubscriptionEstimates,
  }

  const estimatesToUse = homeTokensFeatureAvailable
    ? fetchSubscriptionsEstimatesResult
    : fetchCreateSubscriptionEstimates

  return estimatesToUse
}

function createSubscriptionEstimateBodies(
  availablePlans: IAvailablePlan[] | undefined,
  subscription: TCurrentSubscription | null,
  customer: ICustomer | undefined,
  numberOfHomes: number | undefined,
  currentSubscription: TCurrentSubscription | undefined,
  discountCode?: string
): ICreateSubscriptionEstimateBody[] {
  const addons = subscription?.addons?.map((a) => ({
    ...a,
    quantity: a.quantity ?? 0,
  }))

  if (!availablePlans || !customer) {
    return []
  }

  // Remove all invalid discount codes before updating subscription estimate:
  const coupon_ids = discountCode
    ? getFilteredDiscountCodes({
        discountCodes: [discountCode],
        currentSubscription,
      })
    : undefined

  return availablePlans.map((plan) => ({
    subscription: {
      plan_id: plan.plan_id,
      plan_quantity: subscription?.plan_quantity || numberOfHomes || 1,
    },
    billing_address: {
      line1: customer.billing_address?.line1,
      line2: customer.billing_address?.line2,
      city: customer.billing_address?.city,
      state_code: customer.billing_address?.state_code,
      zip: customer.billing_address?.zip,
      country: customer.billing_address?.country,
    },
    customer: {
      vat_number: customer.vat_number,
    },
    addons,
    invoice_immediately: "true",
    coupon_ids,
  }))
}
