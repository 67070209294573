import {
  IInvoiceEstimate,
  TInvoiceEstimate,
} from "src/components/Account/types"
import { useTranslate } from "src/i18n/useTranslate"
import { formatPrice } from "src/utils/formatPrice"

import { LineItem } from "./LineItem"

export function UsedCreditsLineItem({
  invoiceEstimate,
}: {
  invoiceEstimate: IInvoiceEstimate | TInvoiceEstimate
}) {
  const { t, langKeys } = useTranslate()
  const { credits_applied: creditsApplied } = invoiceEstimate

  if (!creditsApplied || creditsApplied === 0) {
    return null
  }

  const value = `-${formatPrice(
    creditsApplied / 100,
    invoiceEstimate?.currency_code ?? "USD"
  )}`

  return (
    <LineItem
      description={t(langKeys.change_plan_used_credits)}
      value={value}
    />
  )
}
