import { useMutation, useQuery, UseQueryOptions } from "@tanstack/react-query"
import { AxiosError } from "axios"

import { ICustomer } from "src/components/Account/types"
import { API_DEFAULT } from "src/constants/minutApi"
import { IAvailablePlan } from "src/data/billing/types/billingTypes"
import { subscriptionKeys } from "src/data/subscription/queries/subscriptionKeys"
import {
  TPatchSubscriptionBody,
  TPatchSubscriptionError400,
  TPatchSubscriptionResponse,
  TPostSubscriptionBody,
  TPostSubscriptionError400,
  TPostSubscriptionEstimateBody,
  TPostSubscriptionEstimateResponse,
  TPostSubscriptionEstimateUpdateBody,
  TPostSubscriptionEstimateUpdateResponse,
  TSubscriptionsResponse,
} from "src/data/subscription/types/subscriptionTypes"
import { minutApiHttpClient } from "src/utils/minutApiHttpClient"

// https://api.staging.minut.com/latest/docs/internal#post-/subscriptions/estimate/create_subscription
async function postSubscriptionEstimate(body: TPostSubscriptionEstimateBody) {
  const response =
    await minutApiHttpClient.post<TPostSubscriptionEstimateResponse>(
      `${API_DEFAULT}/subscriptions/estimate/create_subscription`,
      body
    )

  return response.data
}

// https://api.staging.minut.com/latest/docs/internal#post-/subscriptions/estimate/update_subscription
async function postSubscriptionEstimateUpdate(
  body: TPostSubscriptionEstimateUpdateBody
) {
  const response =
    await minutApiHttpClient.post<TPostSubscriptionEstimateUpdateResponse>(
      `${API_DEFAULT}/subscriptions/estimate/update_subscription`,
      body
    )

  return response.data
}

// ts-prune-ignore-next
export function useFetchSubscriptionEstimate({
  orgId,
  body,
  options,
}: {
  orgId: string
  body: TPostSubscriptionEstimateBody
  options?: UseQueryOptions<
    TPostSubscriptionEstimateResponse,
    AxiosError,
    TPostSubscriptionEstimateResponse,
    ReturnType<typeof subscriptionKeys.subscriptionEstimate>
  >
}) {
  return useQuery({
    ...options,
    queryKey: subscriptionKeys.subscriptionEstimate(orgId, body),
    queryFn: () => postSubscriptionEstimate(body),
  })
}

// ts-prune-ignore-next
export function useFetchSubscriptionUpdateEstimate({
  orgId,
  body,
  options,
}: {
  orgId: string
  body: TPostSubscriptionEstimateUpdateBody
  options?: UseQueryOptions<
    TPostSubscriptionEstimateUpdateResponse,
    AxiosError,
    TPostSubscriptionEstimateUpdateResponse,
    ReturnType<typeof subscriptionKeys.subscriptionEstimateUpdate>
  >
}) {
  return useQuery({
    ...options,
    queryKey: subscriptionKeys.subscriptionEstimateUpdate(orgId, body),
    queryFn: () => postSubscriptionEstimateUpdate(body),
  })
}

export function useFetchSubscriptionEstimates({
  availablePlans,
  quantity,
  customer,
  discountCode,
  options,
}: {
  availablePlans: IAvailablePlan[]
  quantity: number
  customer: ICustomer | undefined
  discountCode?: string
  options?: UseQueryOptions<
    TPostSubscriptionEstimateResponse[],
    AxiosError,
    TPostSubscriptionEstimateResponse[],
    ReturnType<typeof subscriptionKeys.subscriptionEstimates>
  >
}) {
  async function getSubscriptionEstimate(
    plan: IAvailablePlan
  ): Promise<TPostSubscriptionEstimateResponse> {
    const couponIds = discountCode ? [discountCode] : undefined

    const response = await postSubscriptionEstimate({
      plan_id: plan?.plan_id,
      plan_quantity: quantity,
      billing_country: customer?.billing_address?.country || "US",
      vat_number: customer?.vat_number,
      coupon_ids: couponIds,
    })

    return response
  }

  return useQuery({
    queryKey: subscriptionKeys.subscriptionEstimates(availablePlans),
    queryFn: () =>
      Promise.all(availablePlans.map((plan) => getSubscriptionEstimate(plan))),
    ...options,
  })
}

export function usePostUserSubscription() {
  async function postUserSubscription(body: TPostSubscriptionBody) {
    const response = await minutApiHttpClient.post<TSubscriptionsResponse>(
      `${API_DEFAULT}/subscriptions`,
      body
    )

    return response.data
  }

  return useMutation<
    TSubscriptionsResponse,
    AxiosError<TPostSubscriptionError400>,
    TPostSubscriptionBody
  >({
    mutationFn: postUserSubscription,
  })
}

export function usePatchUserSubscription() {
  // https://api.staging.minut.com/latest/docs/internal#patch-/subscriptions/-subscription_id-
  async function patchSubscription({
    subscriptionId,
    body,
  }: {
    subscriptionId: string
    body: TPatchSubscriptionBody
  }) {
    const response = await minutApiHttpClient.patch<
      TPatchSubscriptionResponse,
      TPatchSubscriptionError400
    >(`${API_DEFAULT}/subscriptions/${subscriptionId}`, body)

    return response.data
  }

  return useMutation({
    mutationFn: patchSubscription,
  })
}
