import styled from "styled-components"

import { SelectedPlan } from "src/components/Account/BillingPortal/ChangePlan/ChangePlan"
import { BilledNowLineItem } from "src/components/Account/BillingPortal/ChangePlan/OrderSummary/BilledNowLineItem"
import { UsedCreditsLineItem } from "src/components/Account/BillingPortal/ChangePlan/OrderSummary/CreditsLineItem"
import { DiscountsLineItem } from "src/components/Account/BillingPortal/ChangePlan/OrderSummary/DiscountsLineItem"
import { SubscriptionLineItem } from "src/components/Account/BillingPortal/ChangePlan/OrderSummary/SubscriptionLineItem"
import {
  formatUTCSecondsDate,
  PlanWithCostEstimate,
} from "src/components/Account/BillingPortal/ChangePlan/utils"
import {
  IDiscount,
  IEstimate,
  TCreditNoteEstimate,
  TDiscount,
  TEstimate,
  TEstimateBreakdown,
} from "src/components/Account/types"
import {
  ISubscriptionEstimateResponse,
  TSubscriptionCurrencyCode,
} from "src/data/billing/types/billingTypes"
import { useFetchUserHomeTokens } from "src/data/homes/queries/homeTokenQueries"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { translatePlanName } from "src/data/user/logic/userPlan"
import { PLAN } from "src/data/user/user"
import { useHomeTokens } from "src/hooks/useHomeTokens"
import { useTranslate } from "src/i18n/useTranslate"
import { Divider } from "src/ui/Divider/Divider"
import { InfoBox } from "src/ui/InfoBox/InfoBox"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import { formatPrice } from "src/utils/formatPrice"

export type OrderSummaryProps = {
  planCost: PlanWithCostEstimate["cost"]
  currencyCode: TSubscriptionCurrencyCode
  selectedPlan: SelectedPlan
  estimate: IEstimate | TEstimate
  breakdown?: TEstimateBreakdown
}

export function OrderSummary({
  planCost,
  currencyCode,
  selectedPlan,
  estimate,
  breakdown,
}: OrderSummaryProps) {
  const { t, langKeys } = useTranslate()
  const { hasHomeTokenRole } = useHomeTokens()
  const user = useGetUser()
  const fetchUserHomeTokens = useFetchUserHomeTokens({
    userId: user.user_id,
    filter: { activated: false },
    options: { enabled: hasHomeTokenRole },
  })

  const homeTokensCount = fetchUserHomeTokens.data?.paging.total_count || 0

  const {
    invoice_estimate,
    next_invoice_estimate,
    subscription_estimate,
    credit_note_estimates,
  } = estimate

  const couponDiscounts =
    invoice_estimate?.discounts?.filter(couponDiscountsFilter) || []

  const isFutureChange =
    !!next_invoice_estimate && subscription_estimate?.next_billing_at

  const nextBillingAt = subscription_estimate?.next_billing_at

  const creditAdjustment = getCreditAdjustment(credit_note_estimates)

  return (
    <Box>
      <MText variant="subtitle">{t(langKeys.order_summary)}</MText>

      <SummaryCard>
        <LineItemBox>
          <SubscriptionLineItem
            planName={translatePlanName(t, selectedPlan.plan)}
            planCost={planCost}
            currencyCode={currencyCode}
            nextBillingAt={nextBillingAt}
          />

          {couponDiscounts.length > 0 && (
            <DiscountsLineItem
              discounts={couponDiscounts}
              currencyCode={currencyCode}
            />
          )}

          {invoice_estimate && !hasHomeTokenRole && (
            <UsedCreditsLineItem invoiceEstimate={invoice_estimate} />
          )}

          <Divider />

          <BilledNowLineItem
            amountDue={invoice_estimate?.amount_due}
            currencyCode={currencyCode}
            taxes={invoice_estimate?.taxes}
            breakdown={breakdown}
          />
        </LineItemBox>
      </SummaryCard>

      <BannerWrapper>
        {creditAdjustment > 0 && (
          <InfoBox title={t(langKeys.change_plan_credit_adjustment_title)}>
            {t(langKeys.change_plan_credit_adjustment_body, {
              value: formatPrice(creditAdjustment / 100, currencyCode),
            })}
          </InfoBox>
        )}

        {isFutureChange && (
          <InfoBox
            title={t(langKeys.change_plan_time_of_change_title)}
            type="warning"
          >
            {t(langKeys.change_plan_time_of_change, {
              date: formatUTCSecondsDate(
                subscription_estimate?.next_billing_at
              ),
            })}
            {hasHomeTokenRole &&
              selectedPlan.plan === PLAN.standard &&
              t(langKeys.home_token_convert_notice, { plan: "Standard" })}
          </InfoBox>
        )}

        {homeTokensCount > 0 && (
          <InfoBox
            title={t(langKeys.change_plan_subscribe_pre_paid_homes_title)}
            type="info"
          >
            {t(langKeys.change_plan_subscribe_pre_paid_homes_description, {
              quantity: homeTokensCount,
            })}
          </InfoBox>
        )}
      </BannerWrapper>
    </Box>
  )
}

function couponDiscountsFilter(discount: IDiscount | TDiscount) {
  return ["document_level_coupon", "item_level_coupon"].includes(
    discount?.entity_type ?? ""
  )
}

function getCreditAdjustment(
  creditNotes:
    | ISubscriptionEstimateResponse["estimate"]["credit_note_estimates"]
    | TCreditNoteEstimate[]
) {
  if (!creditNotes) {
    return 0
  }

  return creditNotes.reduce((prev, curr) => {
    return prev + (curr?.amount_available ?? 0)
  }, 0)
}

const SummaryCard = styled.div`
  border-radius: 14px;
`

const LineItemBox = styled.div`
  display: grid;
  gap: ${spacing.M};
`

const Box = styled.div`
  display: grid;
  gap: ${spacing.M};
`

const BannerWrapper = styled.div`
  display: grid;
  gap: ${spacing.XS};
`
