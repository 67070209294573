import { Dispatch, SetStateAction, useEffect, useState } from "react"
import styled from "styled-components"

import { ChangeSubscription } from "src/components/Account/BillingPortal/ChangePlan/ChangeSubscription"
import { CreateSubscription } from "src/components/Account/BillingPortal/ChangePlan/CreateSubscription"
import { useCustomerCurrency } from "src/components/Account/BillingPortal/ChangePlan/useCustomerCurrency"
import { useFetchPlanEstimates } from "src/components/Account/BillingPortal/ChangePlan/useFetchPlanEstimates"
import { useGetDiscountCode } from "src/components/Account/BillingPortal/ChangePlan/useGetDiscountCode"
import {
  createPlansWithCostEstimate,
  stringToPlan,
} from "src/components/Account/BillingPortal/ChangePlan/utils"
import { ErrorAlert } from "src/components/Account/BillingPortal/ErrorAlert"
import { CustomPlanInfoBox } from "src/components/Account/BillingPortal/YourPlan/CustomPlanInfoBox"
import { ImageBackdrop } from "src/components/ImageBackdrop/ImageBackdrop"
import { breakpoint } from "src/constants/breakpoints"
import {
  useFetchAvailablePlansForUser,
  useFetchCurrentSubscription,
  useFetchOrCreateCustomer,
  useFetchPaymentMethod,
} from "src/data/billing/queries/billingQueries"
import {
  IAvailablePlansForUserResponse,
  TSubscriptionCurrencyCode,
} from "src/data/billing/types/billingTypes"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { TPlan } from "src/data/user/user"
import { useUrlParam } from "src/hooks/useUrlParam"
import { useTranslate } from "src/i18n/useTranslate"
import { DocumentHead } from "src/router/DocumentHead"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { IconButton } from "src/ui/Button/IconButton"
import { mColors } from "src/ui/colors"
import CloseIcon from "src/ui/icons/close.svg"
import backdropImage from "src/ui/images/photo-orange-pink-tower.jpg"
import backdropImagePortrait from "src/ui/images/photo-orange-pink-tower-portrait.jpg"
import { LearnMore } from "src/ui/Link/LearnMore"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MCard } from "src/ui/MCard/MCard"
import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import { ErrorService } from "src/utils/ErrorService"
import { isNil } from "src/utils/genericUtil"
import { debug } from "src/utils/logger"

export function ChangePlan() {
  const user = useGetUser()
  const { t, langKeys } = useTranslate()
  const [selectedPlan, setSelectedPlan] = useState<SelectedPlan>()

  const fetchCurrentSubscription = useFetchCurrentSubscription({
    userId: user.user_id,
  })
  const fetchOrCreateCustomer = useFetchOrCreateCustomer({ user })
  const currencyCode = useCustomerCurrency({
    customer: fetchOrCreateCustomer.data,
    loading: fetchOrCreateCustomer.isLoading,
  })

  const fetchAvailablePlansForUser = useFetchAvailablePlansForUser({
    userId: user.user_id,
  })

  const fetchPaymentMethod = useFetchPaymentMethod({
    paymentSourceId: fetchOrCreateCustomer.data?.primary_payment_source_id,
  })

  const planQueryParamValue = useUrlParam("plan").value

  useEffect(() => {
    if (selectedPlan || !fetchAvailablePlansForUser.data || !currencyCode) {
      return
    }

    handleFetchAvailablePlanSuccess({
      plans: fetchAvailablePlansForUser.data,
      currencyCode,
      setSelectedPlan,
      planQueryParamValue,
    })
  }, [
    fetchAvailablePlansForUser.data,
    currencyCode,
    selectedPlan,
    planQueryParamValue,
  ])

  const selectablePlans =
    fetchAvailablePlansForUser.data?.available_plans.filter(
      (plan) => plan.currency === currencyCode
    )

  const numberOfHomes = fetchAvailablePlansForUser.data?.number_of_homes
  const currentSubscription = fetchCurrentSubscription.data ?? undefined

  const discountCode = useGetDiscountCode()

  const planEstimates = useFetchPlanEstimates({
    customer: fetchOrCreateCustomer.data,
    currencyCode,
    availablePlans: selectablePlans,
    numberOfHomes,
    currentSubscription,
    discountCode,
  })

  const loading =
    fetchOrCreateCustomer.isLoading ||
    fetchAvailablePlansForUser.isLoading ||
    planEstimates.isLoading ||
    fetchCurrentSubscription.isLoading ||
    fetchPaymentMethod.isInitialLoading

  if (loading) {
    return (
      <MainTemplate>
        <Loading />
      </MainTemplate>
    )
  }

  if (
    (fetchCurrentSubscription.isError &&
      fetchCurrentSubscription.error?.response?.status !== 404) ||
    fetchOrCreateCustomer.error ||
    fetchAvailablePlansForUser.error ||
    !fetchAvailablePlansForUser.data ||
    !selectablePlans ||
    !fetchOrCreateCustomer.data ||
    !currencyCode ||
    planEstimates.error ||
    !planEstimates.data
  ) {
    return (
      <MainTemplate>
        <ErrorAlert />
      </MainTemplate>
    )
  }

  const userIsOnCustomBillingModel =
    !isNil(currentSubscription) &&
    currentSubscription.billing_model !== "plan_unit_per_home"

  if (
    currentSubscription?.custom_plan ||
    fetchOrCreateCustomer.data.cf_csm ||
    userIsOnCustomBillingModel
  ) {
    return (
      <MainTemplate>
        <CustomPlanInfoBox />
      </MainTemplate>
    )
  }

  const monthlyPlan = currentSubscription?.billing_period === "month"
  const hasAddons = !!currentSubscription?.addons?.find((a) => a.id)
  if (monthlyPlan && hasAddons) {
    // a monthly plan is legacy and coupled with Guard Assist is custom, so
    // let's tell our users this:
    return (
      <MainTemplate>
        <CustomPlanInfoBox />
      </MainTemplate>
    )
  }

  if (currentSubscription?.subscription_status === "non_renewing") {
    return (
      <MainTemplate>
        <MBanner type="warning">{t(langKeys.change_plan_non_renewing)}</MBanner>
      </MainTemplate>
    )
  }

  if (currentSubscription?.subscription_status === "future") {
    return (
      <MainTemplate>
        <MBanner type="warning">{t(langKeys.change_plan_future)}</MBanner>
      </MainTemplate>
    )
  }

  if (currentSubscription?.subscription_status === "paused") {
    return (
      <MainTemplate>
        <MBanner type="warning">{t(langKeys.change_plan_paused)}</MBanner>
      </MainTemplate>
    )
  }

  if (currentSubscription?.has_scheduled_changes) {
    return (
      <MainTemplate>
        <MBanner type="warning">
          {t(langKeys.change_plan_has_scheduled_changes)}
        </MBanner>
      </MainTemplate>
    )
  }

  const plansWithEstimate = createPlansWithCostEstimate({
    availablePlans: selectablePlans,
    estimates: planEstimates.data,
  })

  if (currentSubscription) {
    return (
      <MainTemplate>
        <ChangeSubscription
          selectedPlan={selectedPlan}
          setSelectedPlan={setSelectedPlan}
          paymentSource={fetchPaymentMethod.data}
          currencyCode={currencyCode}
          plansWithEstimate={plansWithEstimate}
          subscription={currentSubscription}
          discountCode={discountCode}
        />
      </MainTemplate>
    )
  }

  return (
    <MainTemplate>
      <CreateSubscription
        selectedPlan={selectedPlan}
        setSelectedPlan={setSelectedPlan}
        currencyCode={currencyCode}
        paymentSource={fetchPaymentMethod.data}
        plansWithEstimate={plansWithEstimate}
        numberOfHomes={numberOfHomes}
      />
    </MainTemplate>
  )
}

function handleFetchAvailablePlanSuccess({
  plans,
  setSelectedPlan,
  currencyCode,
  planQueryParamValue,
}: {
  plans: IAvailablePlansForUserResponse
  setSelectedPlan: Dispatch<SetStateAction<SelectedPlan | undefined>>
  currencyCode?: TSubscriptionCurrencyCode
  planQueryParamValue?: string
}) {
  debug.log("Plans: ", plans)
  debug.log("CurrencyCode: ", currencyCode)

  const planStateContext = {
    plan_state: {
      fetchedPlans: plans,
      currencyCode,
    },
  }

  const filteredPlansOnCurrency = plans.available_plans.filter(
    (plan) => plan.currency === currencyCode
  )
  const filteredPlanOnQueryParam = filteredPlansOnCurrency.find(
    (p) => p.plan === planQueryParamValue
  )

  if (!plans.current_plan || plans.current_plan?.status === "cancelled") {
    // If the user doesn't have an active subscription, select the first available
    // plan in the list based on user currency or based on the `plan` query parameter

    const plan = filteredPlanOnQueryParam || filteredPlansOnCurrency[0]

    if (!plan) {
      const error = new Error("Filtered available plans array is empty")

      ErrorService.captureException(error, {
        context: {
          ...planStateContext,
          filtered_plan: { availablePlansOnCurrency: filteredPlansOnCurrency },
        },
      })

      return
    }

    setSelectedPlan({ planId: plan.plan_id, plan: stringToPlan(plan.plan) })
  } else if (plans.current_plan.legacy_plan) {
    // If the user is on a legacy plan, select the first available plan that
    // corresponds to the legacy plan. E.g. pro-legacy -> pro 2022
    const plan =
      filteredPlanOnQueryParam ||
      filteredPlansOnCurrency.find((p) => p.plan === plans.current_plan?.plan)

    if (!plan) {
      const error = new Error(
        "Could not find a plan to match with user's legacy plan"
      )

      ErrorService.captureException(error, {
        context: planStateContext,
      })
      return
    }

    setSelectedPlan({
      planId: plan.plan_id,
      plan: stringToPlan(plan.plan),
    })
  } else {
    // Finally if none of the above is true, set the selected available plan
    // to their current plan, or if a plan has been provided through a
    // query parameter pre-select that one.
    const plan =
      filteredPlanOnQueryParam ||
      plans.available_plans.find(
        (p) => p.plan_id === plans.current_plan?.plan_id
      )

    if (!plan) {
      const error = new Error(
        "Could not find an available plan to match with their existing current plan"
      )

      ErrorService.captureException(error, {
        context: planStateContext,
      })
      return
    }

    setSelectedPlan({
      planId: plan.plan_id,
      plan: stringToPlan(plan.plan),
    })
  }
}

function MainTemplate({ children }: { children: React.ReactNode }) {
  const { t, langKeys } = useTranslate()
  const { goBack } = useRouter()

  function handleClose() {
    goBack({ defaultPath: Routes.AccountBilling.location() })
  }

  return (
    <ImageBackdrop
      landscapeImageUrl={backdropImage}
      portraitImageUrl={backdropImagePortrait}
      backgroundColor={mColors.neutral}
    >
      <Card>
        <DocumentHead title={t(langKeys.change_plan)} />

        <MText variant="heading2">{t(langKeys.change_plan)}</MText>

        <StyledIconButton
          variant="subtle"
          size="small"
          onClick={handleClose}
          Icon={CloseIcon}
        />

        <Grid>
          <div>
            <MText variant="body">
              {t(langKeys.change_plan_description)}{" "}
              <LearnMore
                href="https://minut.com/pricing"
                color="unset"
                showExternalIcon={false}
              />
            </MText>
          </div>

          {children}
        </Grid>
      </Card>
    </ImageBackdrop>
  )
}

export type SelectedPlan = {
  planId: string
  plan: TPlan
}

const Grid = styled.div`
  display: grid;
  row-gap: ${spacing.XL};
`

const Card = styled(MCard)`
  position: relative;
  padding: ${spacing.L};
  max-width: 900px;

  @media (${breakpoint.mediumUp}) {
    padding: ${spacing.XL2};
  }
`

const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: ${spacing.M};
  right: ${spacing.L};
`

function Loading() {
  return (
    <div>
      <MSkeleton width="30%" />
      <MSkeleton width="50%" />
      <MSkeleton width="85%" />
      <MSkeleton width="45%" />
      <MSkeleton width="100%" />
    </div>
  )
}
