import { IAvailablePlan } from "src/data/billing/types/billingTypes"
import {
  TPostSubscriptionEstimateBody,
  TPostSubscriptionEstimateUpdateBody,
} from "src/data/subscription/types/subscriptionTypes"

export const subscriptionKeys = {
  all() {
    return ["subscription"] as const
  },

  subscriptionEstimate(orgId: string, filter: TPostSubscriptionEstimateBody) {
    return [...this.all(), orgId, "subscriptionEstimate", filter] as const
  },
  subscriptionEstimateUpdate(
    orgId: string,
    filter: TPostSubscriptionEstimateUpdateBody
  ) {
    return [...this.all(), orgId, "subscriptionUpdateEstimate", filter] as const
  },

  subscriptionEstimates(availablePlans?: IAvailablePlan[]) {
    return [...this.all(), "subscriptionEstimates", availablePlans] as const
  },
} as const
