import { useState } from "react"
import styled from "styled-components"

import { ChangePlanBreakDownDialog } from "src/components/Account/BillingPortal/ChangePlan/Breakdown/ChangePlanBreakDownDialog"
import { TChangePlanBreakdownData } from "src/components/Account/BillingPortal/ChangePlan/Breakdown/changePlanBreakdownTypes"
import {
  IInvoiceEstimate,
  TEstimateBreakdown,
  TInvoiceEstimateTax,
} from "src/components/Account/types"
import { TSubscriptionCurrencyCode } from "src/data/billing/types/billingTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { TextButton } from "src/ui/Button/TextButton"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import { formatPrice } from "src/utils/formatPrice"
import { Optional } from "src/utils/tsUtil"

import { LineItem } from "./LineItem"

export function BilledNowLineItem({
  amountDue,
  taxes,
  currencyCode,
  breakdown,
}: {
  amountDue: IInvoiceEstimate["amount_due"]
  taxes: IInvoiceEstimate["taxes"] | TInvoiceEstimateTax[]
  currencyCode: TSubscriptionCurrencyCode
  breakdown: Optional<TEstimateBreakdown>
}) {
  const { t, langKeys } = useTranslate()
  const [breakdownOpen, setBreakdownOpen] = useState(false)

  const taxDescriptions = taxes?.map((tax) => tax.description).join(",")
  const taxLine = taxDescriptions
    ? `${t(langKeys.billing_total_includes)} ${taxDescriptions}`
    : ""
  const billedNow = formatPrice((amountDue ?? 0) / 100, currencyCode)

  const breakdownData: TChangePlanBreakdownData = {
    billedNow: billedNow,
    vat: taxLine,
    items: breakdown
      ? breakdown.map((b) => ({
          ...b,
          currency_code: b.currency_code ?? currencyCode,
        })) // hack since the breakdown doesn't currently contain the currency code
      : [],
  }

  return (
    <LineItem
      description={
        <div>
          <MText variant="heading3">{t(langKeys.billed_now)}</MText>
          {breakdown?.length && (
            <TaxLine variant="bodyS">
              <TextButton onClick={() => setBreakdownOpen(true)}>
                {t(langKeys.see_breakdown)}
              </TextButton>
            </TaxLine>
          )}
        </div>
      }
      value={
        <div>
          <MText variant="heading3" textAlign="right">
            {billedNow}
          </MText>
          {taxDescriptions && (
            <TaxLine variant="bodyS" color="secondary" textAlign="right">
              {taxLine}
            </TaxLine>
          )}
        </div>
      }
    >
      <ChangePlanBreakDownDialog
        data={breakdownData}
        open={breakdownOpen}
        onClose={() => setBreakdownOpen(false)}
      />
    </LineItem>
  )
}

const TaxLine = styled(MText)`
  margin-top: ${spacing.XS2};
  text-align: right;
`
