import { LazyLoadImage } from "react-lazy-load-image-component"
import styled from "styled-components"

import PlanImage from "/src/ui/illustrations/plan.png"

import {
  formatUTCSecondsDate,
  PlanWithCostEstimate,
} from "src/components/Account/BillingPortal/ChangePlan/utils"
import { ISubscriptionEstimate } from "src/components/Account/types"
import { TSubscriptionCurrencyCode } from "src/data/billing/types/billingTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { mColors } from "src/ui/colors"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import { formatPrice } from "src/utils/formatPrice"

import { LineItem } from "./LineItem"

import "react-lazy-load-image-component/src/effects/opacity.css"

export function SubscriptionLineItem({
  planName,
  planCost,
  currencyCode,
  nextBillingAt,
}: {
  planName: string
  planCost: PlanWithCostEstimate["cost"]
  currencyCode: TSubscriptionCurrencyCode
  nextBillingAt: ISubscriptionEstimate["next_billing_at"] | undefined
}) {
  const { t, langKeys } = useTranslate()

  if (!planCost.plan.total) {
    return null
  }

  return (
    <LineItem
      description={
        <DescriptionBox>
          <ImgBox>
            <PlanImg src={PlanImage} alt="responder" effect="opacity" />
          </ImgBox>
          <div>
            {planName} x {planCost.plan.quantity}{" "}
            {t(langKeys.home, {
              count: planCost.plan.quantity,
            }).toLocaleLowerCase()}
            {nextBillingAt && (
              <MText variant="bodyS" color="secondary">
                {t(langKeys.change_plan_renews)}:{" "}
                {formatUTCSecondsDate(nextBillingAt)}
              </MText>
            )}
          </div>
        </DescriptionBox>
      }
      value={
        <div>
          <MText variant="subtitle" textAlign="right">
            {formatPrice(planCost.plan.total / 100 / 12, currencyCode)}/
            {t(langKeys.month).toLocaleLowerCase()}
          </MText>
          <MText variant="bodyS" color="secondary" textAlign="right">
            {t(langKeys.billed_as)}{" "}
            {formatPrice(planCost.plan.total / 100, currencyCode)}/
            {t(langKeys.year).toLocaleLowerCase()}
          </MText>
        </div>
      }
    ></LineItem>
  )
}

const DescriptionBox = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.S};
`

const ImgBox = styled.div`
  width: 65px;
  height: 65px;
  border-radius: 8px;
  border: 1px solid ${mColors.divider};
  display: flex;
  background: white;
`

const PlanImg = styled(LazyLoadImage)`
  width: 100%;
  height: 100%;
  object-fit: contain;
`
